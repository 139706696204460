/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import { Chart } from "chart.js";
import { Blocks } from 'react-loader-spinner';
// react plugin used to create charts
// reactstrap components
import {
  Badge,
  Button,
  Card, CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media, Pagination, PaginationItem, PaginationLink,
  Progress,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal, Input, FormGroup
} from "reactstrap";

// core components

import { chartOptions, parseOptions, } from "variables/charts.js";
import axios from "axios";
import { getBets, getGames, getSavedBets, saveBet } from "../../../network/ApiAxios";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";

function Bets(props) {
  const [loading, setLoading] = useState(true)
  const [savingBet, setBetSaving] = useState(false)
  const [data, setData] = useState({ })
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentMarket, setCurrentMarket] = useState(-1)
  const [games, setGames] = useState([])
  const [savedBets, setSavedBets] = useState([])
  const [selectedMarket, setSelectedMarket] = useState({outcomes:[]})
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [bet, setBet] = React.useState('');
  
  
  useEffect(() => {
    if(props.match.params.id) {
      const urlParams = new URLSearchParams(window.location.search);
      const game = urlParams.get('game');
      console.log('props', game)
      fetchGames(props.match.params.id, game)
      fetchBets(props.match.params.id, game)
    }
  }, [])
  const fetchGames = async (id, game='nba') => {
    setLoading(true)
    const response = await getBets(id, game)
    if (response.status === 200) {
      const gameData = response.data.games;
      setData(gameData)
      setGames(gameData.bookmakers)
      //setSelectedMarket(gameData.bookmakers && gameData.bookmakers?.length>0 ? gameData.bookmakers[0].markets[0]:[])
      setCurrentPage(1);
      setTotalPages(1)
      setCurrentMarket(0);
      setLoading(false)
      //setGames(response.data.data)
    }
  }
  const fetchBets = async (id) => {
    setLoading(true)
    const response = await getSavedBets(id)
    if (response.status === 200) {
      const bets = response.data.bets;
      setSavedBets(bets)
      setLoading(false)
    }
  }
  const saveUserBet = async (record) => {
    const urlParams = new URLSearchParams(window.location.search);
    const game = urlParams.get('game');
    setdefaultModal(false);
    setBetSaving(true)
    const response = await saveBet({ ...record, gameId: props.match.params.id, type: game || 'nba' })
    if (response.status === 200) {
      Swal.fire('Bet is saved to database')
      fetchBets(props.match.params.id, )
    }
    setBetSaving(false)
  }
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const onChangePage = (page)=>{
    console.log('page', page*10)
    setCurrentPage(page+1)
    setGames(data?.slice(page*10, (page*10)+10))
  }
  return (
    <>
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Bets</h3>
                  </CardHeader>
                  {!loading && <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Team Name
                      </th>
                      {
                        data.odds && data.odds.map((odd)=>
                          <th className="sort" data-sort="budget" scope="col">
                            {odd.market}
                          </th>
                      )}
                    </tr>
                    </thead>
                    <tbody className="list">
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                  <span className="name mb-0 text-sm">
                                    {data.away_team}
                                  </span>
                            </Media>
                          </Media>
                        </th>
                        {data.odds && data.odds.map((odd) =>
                          <td>
                            {`${odd.odds[0]?.price}`}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                  <span className="name mb-0 text-sm">
                                    {data.home_team}
                                  </span>
                            </Media>
                          </Media>
                        </th>
                        {data.odds && data.odds.map((odd) =>
                          <td>
                            {`${odd.odds[1]?.price}`}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </Table>}
                  <CardFooter className="py-4">
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row>
                      <Col sm="6">
                        <h3 className="mb-0">Market</h3>
                      </Col>
                      <Col sm="6">
                        <h3 className="mb-0">Saved Bets</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Row style={{margin: 10}}>
                    <Col sm="6">
                      <Select2
                        value={currentMarket}
                        data={games.map((game, index)=>({id:index, text:game.title}))}
                        options={{
                          placeholder: 'Select Market',
                        }}
                        onChange={(e, index)=>{
                          console.log(e.target.value, index)
                          if(e && e.target.value) {
                            setCurrentMarket(e.target.value)
                            //setSelectedMarket(games[e.target.value].markets[0])
                          }
                        }}
                      />
                    </Col>
                    <Col sm="6">
                      {savedBets.length}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0 d-flex flex-row justify-content-between">
                    <h3 className="mb-0">Player Prop</h3>
                    <Button
                      onClick={()=>{setdefaultModal(true)}}
                      color="info"
                      type="button"
                    >+Add Bet</Button>
                  </CardHeader>
                  {!loading && <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        Market Name
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Name
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Bet Points
                      </th>
                      {/*<th className="sort" data-sort="status" scope="col">
                        Probability
                      </th>*/}
                      <th scope="col">Price</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {games?.map((game) => (
                      <tr style={{'cursor':'pointer'}}
                          onClick={(e)=>{props.history.push(`/admin/bets/${game.id}`)}
                          }>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                <span className="name mb-0 text-sm">
                                  {game.description}
                                </span>
                            </Media>
                          </Media>
                        </th>
                        <td>
                          {game.name}
                        </td>
                        <td>
                          {game.point}
                        </td>
                        {/*<td>
                          {game.probability}
                        </td>*/}
                        <td>
                          {game.price}
                        </td>
                        <td>
                          <Button
                            onClick={(e)=>{
                              e.stopPropagation();
                              e.preventDefault();
                              if(currentMarket>=0) {
                                const market = games[currentMarket];
                                const _data = {
                                  ...game,
                                  market: market.title,
                                  id: `${data.id}${game.name}${game.point}${game.price}${game.description}`,
                                  commence_time: data.commence_time,
                                  sport_key: data.sport_key,
                                  home_team: data.home_team,
                                  away_team: data.away_team
                                }
                                console.log('data', _data)
                                saveUserBet(_data);
                              }
                            }}
                            disabled={savingBet}
                            style={{width: 70}}
                            color="primary"
                            type="button" size="sm">
                            Save
                          </Button>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>}
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left"/>
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {
                          Array.from(Array(totalPages)).map((item, index) =>
                            <PaginationItem className={index + 1 === currentPage ? "active" : ""}>
                              <PaginationLink
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault()
                                  onChangePage(index)
                                }}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Blocks
        visible={loading}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{ display: 'block', margin: 'auto' }}
        wrapperClass="blocks-wrapper"
      />
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Add New Bet
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="bet"
            >
              Bet
            </label>
            <Input
              id="bet"
              placeholder="Enter proposed bet"
              type="text"
              onChange={(e) => {
                setBet(e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            onClick={() => saveUserBet({...data, manualBet: bet})}
            color="primary" type="button">
            Save changes
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Bets;
