/*!

=========================================================
* Argon Dashboard PRO React Nodejs - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react-nodejs
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim
* Coded by ProjectData

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import { Chart } from "chart.js";
import { Blocks } from 'react-loader-spinner'
// react plugin used to create charts
// reactstrap components
import {
  Badge,
  Card, CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media, Pagination, PaginationItem, PaginationLink,
  Progress,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

// core components

import { chartOptions, parseOptions, } from "variables/charts.js";
import axios from "axios";
import { getGames } from "../../../network/ApiAxios";
import moment from "moment";
import AdminNavbar from "../../../components/Navbars/AdminNavbar";

function Dashboard(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [games, setGames] = useState([])
  const type = props.location.pathname === '/admin/nfl' ? 'nfl' : 'nba';
  
  useEffect(() => {
    fetchGames(type)
    console.log('props', props)
  }, [])
  const fetchGames = async (type) => {
    setLoading(true)
    const response = await getGames(type)
    if (response.status === 200) {
      const games = response.data.games;
      setData(games)
      setGames(games.slice(0, 10))
      setCurrentPage(1);
      setTotalPages(Math.ceil(games.length / 10))
      setLoading(false)
      //setGames(response.data.data)
    }
  }
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const onChangePage = (page)=>{
    console.log('page', page*10)
    setCurrentPage(page+1)
    setGames(data.slice(page*10, (page*10)+10))
  }
  return (
    <>
      <Container className="" fluid>
        <Row>
          <Col xl="12">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Games</h3>
                  </CardHeader>
                  {!loading && <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                      <th className="sort" data-sort="name" scope="col">
                        League
                      </th>
                      <th className="sort" data-sort="budget" scope="col">
                        Home Team
                      </th>
                      <th className="sort" data-sort="status" scope="col">
                        Away Team
                      </th>
                      <th scope="col">Live</th>
                      <th className="sort" data-sort="completion" scope="col">
                        Start Date
                      </th>
                      <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {games.map((game) => (
                      <tr style={{'cursor':'pointer'}}
                          onClick={(e)=>{props.history.push(`/admin/bets/${game.id}?game=${type}&market=${game.market[0]}`)}
                          }>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                                <span className="name mb-0 text-sm">
                                  {game.sport_title}
                                </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="budget">{game.home_team}</td>
                        <td>
                          {game.away_team}
                        </td>
                        <td>
                          <Badge className="badge-dot mr-4" color="">
                            <i className="bg-warning"/>
                            <span className="status">{game.is_live ? 'Live' : 'Not Live'}</span>
                          </Badge>
                        </td>
                        <td>
                          {moment(game.commence_time).format()}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v"/>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </Table>}
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left"/>
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {
                          Array.from(Array(totalPages)).map((item, index) =>
                            <PaginationItem className={index + 1 === currentPage ? "active" : ""}>
                              <PaginationLink
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault()
                                  onChangePage(index)
                                }}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <Blocks
        visible={loading}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{ display: 'block', margin: 'auto' }}
        wrapperClass="blocks-wrapper"
      />
    </>
  );
}

export default Dashboard;
